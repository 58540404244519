.headerBox {
    background: url('../../public/images/banner_ap_portal.jpg') no-repeat;
    background-size: 252% 250%;
    width: 100%;
    min-height: 350px;
    background-position: left 87% bottom 29%;
    display: flex;
    align-items: center;
    justify-content: center;
    /*filter: grayscale(1) opacity(0.8);*/

    filter: hue-rotate(48deg);
}

    .ictaDivider {
        background-color: #F5A113;
        min-height: 5px;
        width: 100%;
    }

.gradientBanner {
    background: linear-gradient(to right, #00406E, #00406E 70%, #FFFFFF);
}

.ictaLogo {
    background: url('../../public/images/logo-icta.png') no-repeat;
    background-position: center;
    background-size: 100%;
    height: 98px;
}

.ictaBlue {
    background-color: #00406E;
    height: 98px;
}

.ictaWhite {
    background-color: white;
    height: 98px;
}

.ictaBanner {
    background: url('../../public/images/Header_ICTA.png') no-repeat;
    background-size: cover;
    height: 98px;
}

.sponsorBanner {
    background: url('../../public/images/VertexPharma-logo.png') no-repeat;
    background-position: center;
    background-size: 50%;
    height: 98px;
}

.headerBox > h1 {
    font-family: Montserrat;
    font-weight: 600;
    /*color: #103958;*/
    color: rgb(82, 36, 127);
    text-align: center;
    font-size: 40pt;
    letter-spacing: 1.25px;
    filter: hue-rotate(-48deg);
}

.headerLogos {
    position: fixed;
    z-index: 7;
}